import { CommonModule } from '@angular/common';
import {
  Component,
  HostBinding,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { LimitReportAdditionalInfosComponent } from '@backoffice-frontend/limit-report/configuration/ui-configuration';
import {
  LimitReportAdditionalInfosService,
  LimitReportPosition,
  LimitReportStore,
} from '@backoffice-frontend/limit-report/domain';
import { FilterParam, ID, StringHelper } from '@clean-code/shared/common';
import { ActionButtonModule } from '@clean-code/shared/components/ui-action-button';
import { CommentListDialogComponent } from '@clean-code/shared/components/ui-comment';
import { ConfirmService } from '@clean-code/shared/components/ui-confirm';
import { DataTableBaseComponent } from '@clean-code/shared/components/ui-data-table';
import {
  DataTableContainerComponent,
  TablePreviewComponent,
  UiMatTableModule,
} from '@clean-code/shared/components/ui-mat-table';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faInfo } from '@fortawesome/pro-light-svg-icons';
import { TranslocoModule } from '@jsverse/transloco';
import { switchMap } from 'rxjs/operators';
import { LimitReportPositionOtherListFacade } from '../../services/limit-report-position-list.facade';
import { LimitReportOtherPositionSearchComponent } from '../limit-report-other-position-search/limit-report-other-position-search.component';

@Component({
  standalone: true,
  selector: 'backoffice-frontend-limit-report-other-position-list',
  templateUrl: './limit-report-position-list.component.html',
  styleUrls: ['./limit-report-position-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [
    CommonModule,
    TranslocoModule,

    FontAwesomeModule,

    UiMatTableModule,
    DataTableContainerComponent,
    LimitReportOtherPositionSearchComponent,
    LimitReportAdditionalInfosComponent,
    ActionButtonModule,
    TablePreviewComponent,
  ],
})
export class LimitReportPositionOtherListComponent extends DataTableBaseComponent<LimitReportPosition> {
  @HostBinding('class') class = 'limit-report-power-position-list';

  public parentIdKeyName = 'id';

  public stringHelper = StringHelper;

  public faInfo = faInfo;

  #limitReportStore = inject(LimitReportStore);
  #id$ = toObservable(this.#limitReportStore.id);
  contractId = this.#limitReportStore.contractId;
  public additionalData$ = this.#id$.pipe(
    switchMap((id: ID) =>
      this.limitReportAdditionalInfosService.limitReportAdditionalData$(
        id,
        'Power'
      )
    )
  );

  constructor(
    public limitReportAdditionalInfosService: LimitReportAdditionalInfosService,
    public service: LimitReportPositionOtherListFacade,
    private confirmService: ConfirmService
  ) {
    super(service);

    this.defaultSort = [{ prop: 'designation', dir: 'ASC' }];
    const reportIdFilter = {
      key: 'limitReportId',
      value: '' + this.#limitReportStore.id(),
    } as FilterParam;
    this.addFilterParams = [reportIdFilter];
  }

  public showComments(parentIdName: string, id: ID) {
    this.confirmService
      .open(
        '',
        '',
        CommentListDialogComponent,
        { parentIdName: parentIdName, id: id },
        { width: '800px' }
      )
      .subscribe();
  }
}
