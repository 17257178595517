<cc-table-preview>
  <cc-table-container [isLoading]="isLoading$ | async">
    <ng-container table-filter>
      <limit-report-other-position-search></limit-report-other-position-search>
    </ng-container>
    <div table-buttons class="flex flex-row items-center">
      <cc-add-action-button (onClick)="add()"></cc-add-action-button>
    </div>
    <backoffice-frontend-limit-report-additional-infos
      [additionalData]="additionalData$ | async"
      >
    </backoffice-frontend-limit-report-additional-infos>

    <bo-mat-table
      #table
      matSort
      [data]="paging$ | async"
      (matSortChange)="onSort($event)"
      (pageChange)="setPage($event)"
      >
      <ng-container matColumnDef="hasComments">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-11">
          {{ 'common.COMMENTS' | transloco }}
        </th>
        <td mat-cell *matCellDef="let element" class="w-11">
          <div (click)="showComments('LimitReportOtherPositionId', element.id)">
            @if (element.hasComments) {
              <fa-icon
                class="table-info-icon"
                [icon]="faInfo"
                >
              </fa-icon>
            }
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="buyingProcess">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'limit-reports.position.fields.BUYING_PROCESS' | transloco }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{
          'limit-reports.common.buying-process.' + element.buyingProcess
          | transloco
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="designation">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'limit-reports.position.fields.DESIGNATION' | transloco }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.designation }}
        </td>
      </ng-container>

      <ng-container matColumnDef="createdDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'limit-reports.position.fields.CREATION_DATE' | transloco }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.createdDate | date : 'dd.MM.yyyy HH:mm' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'limit-reports.position.fields.AMOUNT' | transloco }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.amount }}
        </td>
      </ng-container>

      <ng-container matColumnDef="tradeDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'limit-reports.position.fields.TRADING_DATE' | transloco }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.tradeDate | date : 'shortDate' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="price">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'limit-reports.position.fields.PRICE' | transloco }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.price | number : '.2-2' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="basePrice">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'limit-reports.position.fields.BASE_PRICE' | transloco }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.basePrice | number : '.2-2' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="limitStrategy">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'limit-reports.position.fields.LIMIT_STRATEGY' | transloco }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{
          'limit-reports.common.limit-strategy.' + element.limitStrategy
          | transloco
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="tradeType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'limit-reports.position.fields.TRADE_TYPE' | transloco }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ 'bo.common.trade-type.' + element.tradeType | transloco }}
        </td>
      </ng-container>
    </bo-mat-table>
  </cc-table-container>
</cc-table-preview>
