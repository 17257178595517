import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FilterParam } from '@clean-code/shared/common';
import { TableStateService } from '@clean-code/shared/components/ui-mat-table';
import { TranslocoModule } from '@jsverse/transloco';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { LimitReportPositionOtherListFacade } from '../../services/limit-report-position-list.facade';

@Component({
  standalone: true,
  selector: 'limit-report-other-position-search',
  templateUrl: './limit-report-other-position-search.component.html',
  imports: [ReactiveFormsModule, TranslocoModule, MatSlideToggleModule],
})
export class LimitReportOtherPositionSearchComponent
  implements OnInit, OnDestroy
{
  private closeSubject = new Subject<void>();
  public filterControl = new UntypedFormGroup({
    showOnlyPassiveControl: new UntypedFormControl(false),
  });

  #tableStateService = inject(TableStateService);

  constructor(
    public limitReportPositionOtherListFacade: LimitReportPositionOtherListFacade
  ) {}

  ngOnInit() {
    this.filterControl.controls.showOnlyPassiveControl.valueChanges
      .pipe(
        debounceTime(200),
        tap((value: boolean) => {
          let filterParam: FilterParam;
          if (value) {
            filterParam = {
              key: 'onlyPassive',
              value: 'true',
            } as FilterParam;
          } else {
            filterParam = {
              key: 'onlyPassive',
              value: 'false',
            } as FilterParam;
          }

          this.#tableStateService.upsertFilters([filterParam]);
        }),
        takeUntil(this.closeSubject)
      )
      .subscribe();

    this.filterControl.controls.showOnlyPassiveControl.setValue(false);
  }

  ngOnDestroy(): void {
    this.closeSubject.next();
    this.closeSubject.complete();
  }
}
